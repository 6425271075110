import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from '@abacritt/angularx-social-login';
import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { EuroinnovaLoginProvider } from 'src/providers/euroinnova-login-provider';
import { LinkedinLoginProvider } from 'src/providers/linkedin-login-provider';

import { SnackbarStatus } from '../definitions/snackbar-status.enum';
import { CustomSocialUser } from '../models/custom-social-user';
import { CustomerModuleKey } from '../models/customer';
import { RegisterOAuth } from '../models/user';
import { CustomAppleLoginProvider } from '../providers/apple-login-provider';
import { AuthService } from '../shared-components/ng-login/auth/auth.service';
import { LoadingStore } from '../stores/loading.store';
import { ApiService } from './api.service';
import { CustomSnackbarService } from './custom-snackbar.service';
import { CustomerService } from './customer.service';
import { DomainConfigService } from './domain-config.service';

@Injectable({
  providedIn: 'root'
})
export class SocialLoginService {
  private authService = inject(AuthService);
  private apiService = inject(ApiService);
  private domainConfService = inject(DomainConfigService);
  private loading = inject(LoadingStore);
  private snackbarService = inject(CustomSnackbarService);
  private socialAuthService = inject(SocialAuthService);
  private translateService = inject(TranslateService);
  private customerService = inject(CustomerService);

  private loginEuroinnovaOptions;
  private loginOptions;

  loginResponse = new BehaviorSubject(false);
  private loginSuscription!: Subscription | undefined;

  constructor() {
    const customerConfig = this.domainConfService.getConfigForSubdomain(this.apiService.getDomain());

    /* eslint-disable @typescript-eslint/naming-convention */
    this.loginEuroinnovaOptions = {
      baseUrl: customerConfig.socialLoginConfig?.euroinnova?.baseUrl,
      client_id: customerConfig.socialLoginConfig?.euroinnova?.clientId,
      response_type: 'code',
      scope: 'user_read',
      redirect_uri: window.location.origin
    };
    /* eslint-enable @typescript-eslint/naming-convention */

    const euroInnovaClientId = customerConfig.socialLoginConfig?.euroinnova.clientId;
    const linkedInConfig = customerConfig.socialLoginConfig?.linkedin;
    const googleConfig = customerConfig.socialLoginConfig.google;
    const appleConfig = customerConfig.socialLoginConfig.apple;

    /* eslint-disable @typescript-eslint/naming-convention */
    this.loginOptions = {
      [FacebookLoginProvider.PROVIDER_ID]: {
        scope: 'public_profile email',
        responseType: 'token'
      },
      [EuroinnovaLoginProvider.PROVIDER_ID]: {
        client_id: euroInnovaClientId,
        response_type: 'code',
        scope: 'user_read',
        redirect_uri: window.location.origin
      },
      [LinkedinLoginProvider.PROVIDER_ID]: {
        client_id: linkedInConfig?.clientId,
        response_type: 'code',
        scope: linkedInConfig?.scope,
        redirect_uri: window.location.origin
      },
      [GoogleLoginProvider.PROVIDER_ID]: {
        client_id: googleConfig.clientId,
        response_type: 'code',
        scope: googleConfig.scope,
        redirect_uri: window.location.origin
      },
      [CustomAppleLoginProvider.PROVIDER_ID]: {
        client_id: appleConfig.clientId,
        response_type: 'code',
        scope: appleConfig.scope,
        redirect_uri: window.location.origin
      }
    };
    /* eslint-enable @typescript-eslint/naming-convention */
  }

  async handleAuthState(authorizationCode?: string, language: string = ''): Promise<void> {
    if (authorizationCode) {
      const parameters = new RegisterOAuth(
        '',
        authorizationCode,
        this.loginEuroinnovaOptions.client_id,
        language,
        this.loginEuroinnovaOptions.redirect_uri
      );

      this.authService.login(parameters).subscribe((response) => {
        this.loading.stop();
        this.loginResponse.next(!!response);
      });
    } else {
      this.loginSuscription = this.socialAuthService.authState.subscribe((user: CustomSocialUser) => {
        if (user != null) {
          const clientId = this.loginOptions[user.provider]?.client_id || '';
          const redirectUri = this.loginOptions[user.provider]?.redirect_uri || '';
          if (!language) {
            const currentLanguage = localStorage.getItem('currentLanguage') || 'es';
            language = currentLanguage.replace(/^"|"$/g, '');
          }
          const parameters = user.authorizationCode
            ? new RegisterOAuth('', user.authorizationCode, clientId, language, redirectUri)
            : new RegisterOAuth(user.authToken, user.code ?? '', clientId, language, redirectUri);

          this.authService.login(parameters, user.provider).subscribe((response) => {
            this.loading.stop();
            this.unsubscribe();
            this.loginResponse.next(!!response);
          });
        }
      });
    }
  }

  signin(data: string = '', ssoEnabled = false): void {
    if (this.customerService.isCustomerModuleEnabled(CustomerModuleKey.ENABLEDSSO) && !ssoEnabled) {
      this.socialAuthService
        .signIn(data, this.loginOptions[data])
        .then()
        .catch((error) => {
          this.unsubscribe();
          this.handleOauthError(error);
        });
      this.handleAuthState();
    } else {
      const baseUrl =
        `${this.loginEuroinnovaOptions.baseUrl}?client_id=${this.loginEuroinnovaOptions.client_id}` +
        `&response_type=${this.loginEuroinnovaOptions.response_type}&` +
        `redirect_uri=${this.loginEuroinnovaOptions.redirect_uri}&scope=${this.loginEuroinnovaOptions.scope}`;

      const url = data ? `${baseUrl}&lang=${data}` : baseUrl;

      window.location.replace(url);
    }
  }

  private handleOauthError(error: string): void {
    this.loading.stop();
    if (error !== 'User cancelled login or did not fully authorize.') {
      this.snackbarService.present(this.translateService.instant('defaultErrors.somethingWrong'), SnackbarStatus.Error);
    }
  }

  unsubscribe(): void {
    if (this.loginSuscription) {
      this.loginSuscription.unsubscribe();
      this.loginSuscription = undefined;
    }
  }
}
