import { GoogleInitOptions, GoogleLoginProvider } from '@abacritt/angularx-social-login';
import { Inject, Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { INTERVAL_DELAY } from 'src/utils/constants';

import { ApiService } from '../services/api.service';
import { SubdomainConfigService } from '../services/subdomain-config.service';

@Injectable({ providedIn: 'root' })
export class CustomGoogleLoginProvider extends GoogleLoginProvider {
  constructor(
    private apiService: ApiService,
    private domainConfService: SubdomainConfigService,
    @Inject(Object) initOptions?: GoogleInitOptions
  ) {
    // TODO: Revert this, this is a fix for the moment to compile
    // eslint-disable-next-line max-len
    // const clientId = subdomainConfService.getConfigForSubdomain(apiService.getApiHostname()).socialLoginConfig?.google
    //       .clientId;
    const socialLoginConfig = domainConfService.getConfigForSubdomain(apiService.getApiHostname()).socialLoginConfig;
    const clientId = socialLoginConfig?.google?.clientId ?? '';

    super(clientId, initOptions);
  }

  override async signIn(): Promise<any> {
    const accessToken = await this.getAccessToken();

    return Promise.resolve({ authToken: accessToken });
  }

  override getAccessToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      const interval = setInterval(() => {
        if ((this as any)._tokenClient.h?.closed) {
          window.clearInterval(interval);
          reject('window closed');
        }
      }, INTERVAL_DELAY);

      if (!(this as any)._tokenClient) {
        if ((this as any)._socialUser.value) {
          reject('No token client was instantiated, you should specify some scopes.');
        } else {
          reject('You should be logged-in first.');
        }
      } else {
        (this as any)._tokenClient.requestAccessToken({
          hint: (this as any)._socialUser.value?.email
        });
        (this as any)._receivedAccessToken.pipe(take(1)).subscribe(resolve);
      }
    });
  }
}
